<template>
	<div id="container">
		<div id="leftside">
			<div id="toolbar">
				<div class="search">
					<input v-model="treeViewSearch" type="text" placeholder="Search" />
				</div>
				<div class="icon">
					<v-icon @click="expand">mdi-arrow-expand-vertical</v-icon>
				</div>
				<div class="icon">
					<v-icon @click="collapse">mdi-arrow-collapse-vertical</v-icon>
				</div>
			</div>
			<v-treeview ref="treeview" :search="treeViewSearch" color="#38a6f1" v-model="tree" return-object :open="initiallyOpen" :items="items" activatable item-key="id" @update:active="onActive" @input="onActive" @active="onActive" selection-type="independent">
				<template v-slot:prepend="{ item, open }">
					<v-icon v-if="!item.nodeType || !nodes[item.nodeType]">
						{{ open ? "mdi-folder-open" : "mdi-folder" }}
					</v-icon>
					<v-icon v-else>
						{{ nodes[item.nodeType] }}
					</v-icon>
				</template>
				<template v-slot:append="{ item }">
					<div class="suffix">
						<span>{{minMaxOccurs(item)}}</span>
						<v-icon v-if="item.documentation" style="font-size: 0.7rem">mdi-comment</v-icon>
					</div>
				</template>
				<template v-slot:label="{ item }">
					<div @contextmenu.prevent="highlightXml(item.name, true)">{{ item.name }}</div>
				</template>
			</v-treeview>
		</div>
		<div id="rightside">
			<div id="top">
				<ul>
					<li>Name: <span>{{details.name}}</span></li>
					<li>Documentation: <span>{{details.documentation}}</span></li>
					<li>Flag: <span>{{details.flag}}</span></li>
					<li>Type Name: {{details.typeName}}</li>
					<li>Node Type: {{details.nodeType}}</li>
					<li>Base: <span>{{details.base}}</span></li>
					<li>Value: <span>{{details.value}}</span></li>
					<li>Limit: <span>{{details.limit}}</span></li>
				</ul>

			</div>
			<div id="bottom" ref="bottom">
				<ssh-pre language="js" reactive dark ref="sshpre">
					{{remitXml}}
				</ssh-pre>
			</div>
		</div>
	</div>
</template>

<script>
	import remit from "@/plugins/main";
	import SshPre from 'simple-syntax-highlighter';
	import 'simple-syntax-highlighter/dist/sshpre.css';
	import Mark from 'mark.js';

	export default {
		data: () => ({
			remitXml: remit.document,
			initiallyOpen: ["public"],
			nodes: {
				"xs:element": "mdi-application-brackets-outline",
				"xs:complexType": "mdi-cogs",
				"xs:simpleType": "mdi-cog",
				"xs:maxLength": "mdi-circle-medium",
				"xs:minLength": "mdi-circle-medium",
				"xs:pattern": "mdi-circle-medium",
				"xs:enumeration": "mdi-circle-medium",
				"xs:minInclusive": "mdi-circle-medium",
				"xs:totalDigits": "mdi-circle-medium",
				"xs:fractionDigits": "mdi-circle-medium",
			},
			tree: [],
			items: remit.schema,
			details: {
				id: null,
				name: "",
				nodeType: null,
				typeName: null,
				documentation: null,
				value: null,
				flag: null,
			},
			treeViewSearch: null,
		}),

		methods: {
			onActive(items) {
				this.details.id = items[0]?.id;
				this.details.name = items[0]?.name;
				this.details.nodeType = items[0]?.nodeType;
				this.details.typeName = items[0]?.typeName;
				this.details.documentation = items[0]?.documentation;
				this.details.base = items[0]?.base;
				this.details.value = items[0]?.value;
				this.details.flag = items[0]?.flag;
				this.details.limit = this.minMaxOccurs(items[0]);

				// if (items.length > 0) {
				// 	this.highlightXml(items[0].name);
				// } else {
				// 	this.unhighlightXml();
				// }
			},

			highlightXml(search, scroll = false) {
				let markInstance = new Mark(this.$refs.sshpre.$el);
				markInstance.unmark({
					done: () => {
						markInstance.mark(search, {
							done: () => {
								if (!scroll) return;
								let results = this.$refs.sshpre.$el.getElementsByTagName("mark");
								if (results.length > 0) {
									this.$refs.bottom.scrollTo(0, results[0].offsetTop);
								}
							}
						});
					}
				});
			},

			async unhighlightXml() {
				let markInstance = new Mark(this.$refs.sshpre.$el);
				markInstance.unmark();
			},

			expand() {
				this.$refs.treeview.updateAll(true);
			},

			collapse() {
				this.$refs.treeview.updateAll(false);
			},

			minMaxOccurs(item) {
				if (!item.minOccurs && !item.maxOccurs) return null;

				let maxOccurs = item.maxOccurs ?? "1";
				if (item.maxOccurs == "unbounded") maxOccurs = "n";

				let minOccurs = item.minOccurs ?? "1";
				if (item.minOccurs == "unbounded") minOccurs = "n";

				return `[${minOccurs}..${maxOccurs}]`
			}
		},

		components: {
			SshPre,
		}
	};
</script>

<style lang="scss" scoped>
	.suffix {
		font-size: 0.7rem;
		display: flex;
		justify-content: right;
	}

	#container {
		box-sizing: content-box;
		height: 100vh;
		display: flex;

		#leftside {
			flex: 1;
			overflow-y: auto;
			border: 1px solid grey;
			padding: 0px;

			#toolbar {
				display: flex;
				justify-content: right;
				.icon {
					flex: 0 0 30px;
					border: 1px solid Black;
					align-content: center;
					justify-content: center;
					display: flex;
				}
				.search {
					flex: 1;
					border: 1px solid Black;
					input {
						width: calc(100% - 1rem);
						padding-left: 1rem;
						height: 100%;
					}
				}
			}
		}

		#rightside {
			display: flex;
			flex: 2;
			flex-direction: column;
			border: 1px solid grey;
		}

		#top {
			display: flex;
			flex: 1;
			border: 1px solid grey;
			padding: 20px;
			ul {
				padding-left: 20px;
				font-size: 1.5rem;
				li span {
					font-weight: bold;
				}
			}
		}

		#bottom {
			display: flex;
			flex: 4;
			overflow-y: auto;
			background-color: #262626;
			ssh-pre {
				white-space: pre-wrap;
				width: 100%;
				border: 1px solid grey;
			}
		}

		.theme--dark.v-treeview {
			color: Green !important;
		}
	}
</style>
