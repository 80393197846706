//set NODE_OPTIONS=--openssl-legacy-provider

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

//import pd from 'pretty-data';

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
