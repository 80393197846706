/* eslint-disable no-unused-vars */
// const util = require('util');
let remit = require('./remit');
var xmldoc = require('xmldoc');

var document = new xmldoc.XmlDocument(remit.default.data);

let schema = [{ id: 0, nodeType: "root", name: "REMIT", children: [] }];
let types = [];


function parseNode(node, parentNode, childNodeArray, id = 0) {
	node.eachChild((child) => {
		id++;
		let nodeType = child.name;
		let newNode = { children: [], id: `${id}` };

		if (nodeType === "xs:element") {
			newNode.nodeType = child.name;
			newNode.name = child.attr.name;
			newNode.typeName = child.attr.type;
			newNode.minOccurs = child.attr?.minOccurs;
			newNode.maxOccurs = child.attr?.maxOccurs;
			childNodeArray.push(newNode);
			id = parseNode(child, newNode, newNode.children, id);
		} else if (nodeType === "xs:complexType" || nodeType === "xs:simpleType") {
			if (child.attr.name && child.attr.name != "") {
				let typesLength = types.push({ id: `${id}`, nodeType, name: child.attr.name, children: [] })
				id = parseNode(child, types[typesLength - 1], types[typesLength - 1].children, id);
			} else {
				parentNode.prop = "complexType";
				id = parseNode(child, parentNode, newNode.children, id);
			}
		} else if (nodeType === "xs:sequence") {
			parentNode.flag = "sequence";
			id = parseNode(child, parentNode, parentNode.children, id);
		} else if (nodeType === "xs:choice") {
			parentNode.flag = "choice";
			id = parseNode(child, parentNode, parentNode.children, id);
		} else if (nodeType === "xs:restriction") {
			parentNode.base = child.attr.base;
			id = parseNode(child, parentNode, parentNode.children, id);
		} else if (nodeType === "xs:annotation") {
			id = parseNode(child, parentNode, parentNode.children, id);
		} else if (nodeType === "xs:documentation") {
			parentNode.documentation = child.val;
			id = parseNode(child, parentNode, parentNode.children, id);			
		} else if (nodeType === "xs:enumeration" || nodeType === "xs:maxLength" || nodeType === "xs:minLength" || nodeType === "xs:pattern" || nodeType === "xs:minInclusive" || nodeType === "xs:totalDigits" || nodeType === "xs:fractionDigits") {
			parentNode.children.push({ id, nodeType, name: `${nodeType}: ${child.attr.value}`, value: child.attr.value, children: [] });
		} else {
			id = parseNode(child, parentNode, parentNode.children, id);
		}
	});

	return id;
}

parseNode(document, schema[0], schema[0].children);

// console.log( document.childWithAttribute("name", "priceType") );


// console.log(util.inspect(types, false, null, true /* enable colors */));
// console.log(util.inspect(schema, false, null, true /* enable colors */));

function addTypes(node, parent) {
	node.forEach(r => {

		r.id = `${parent.id}-${r.id}`;

		if (r.typeName && r.typeName != "" && r.children.length == 0) {
			const [prefix, name] = r.typeName.split(":");
			if (prefix === "xs") return;
			let addType = types.find(t => t.name == name);

			let newType = JSON.parse(JSON.stringify(addType));
			r.children.push(newType);
		}
		
		if (r.children.length > 0) {
			addTypes(r.children, r);
		}


	});
}

addTypes(schema, schema[0]);


// console.log(util.inspect(schema, false, null, true /* enable colors */));
//console.log(schema);


export default { schema, document: remit.default.data };





